import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../../Redux/reducer";
import { useNavigate, useParams } from "react-router-dom";
import {
  editCustomerTicket,
  getACustomerTicket,
  postCustomerTicket,
} from "../../../../Service";
import { FaFilePdf } from "react-icons/fa"; // For PDF icon

const initialData = {
  subject: "",
  description: "",
  attachments: [], // Array to store selected files
};
const RaiseTicketForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(initialData);
  const [ticketId, setTicketId] = useState("");
  const [previews, setPreviews] = useState([]); // Array for image previews or PDF files

  const goto = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(id, "Id");

    dispatch(setActivePage("ticket"));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getACustomerTicket(id);
        // setData(data.tickets);
        setFormData({
          subject: data.ticket.subject,
          description: data.ticket.description,
          attachments: [],
        });
        setTicketId(data.ticket.ticket_id);

        // Process the existing attachments for previews and formData
        const existingPreviews = data.ticket.attachment.map((filePath) => {
          const fileType = filePath.endsWith(".pdf") ? "pdf" : "image";
          return fileType === "pdf"
            ? { type: "pdf", name: filePath.split("/").pop() }
            : { type: "image", url: filePath };
        });

        // Create an attachments array to store the file info for form submission
        const existingAttachments = data.ticket.attachment.map((filePath) => {
          return { url: filePath };
        });

        setPreviews(existingPreviews);
        setFormData((prevData) => ({
          ...prevData,
          attachments: existingAttachments,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    if (id !== "add") {
      fetchCustomerList();
    }
  }, [id]);

  useEffect(() => {
    console.log(previews);
  }, [previews]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert file list to an array
    const newAttachments = [...formData.attachments, ...files];
    setFormData({
      ...formData,
      attachments: newAttachments,
    });

    // Create previews for image files and handle PDFs differently
    const previewsArray = files.map((file) => {
      if (file.type === "application/pdf") {
        return { type: "pdf", name: file.name }; // Store only name for PDF
      } else {
        return { type: "image", url: URL.createObjectURL(file) }; // Store image URL for images
      }
    });
    setPreviews((prevPreviews) => [...prevPreviews, ...previewsArray]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const newAttachments = formData.attachments.filter(
      (_, index) => index !== indexToRemove
    );
    const newPreviews = previews.filter((_, index) => index !== indexToRemove);

    setFormData({
      ...formData,
      attachments: newAttachments,
    });
    setPreviews(newPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fetchFileFromUrl = async (url) => {
      try {
        // Fetch the file from the URL
        const response = await fetch(url);

        // Check if the response is okay (status 200-299)
        if (!response.ok) {
          throw new Error(
            `Failed to fetch file from URL: ${response.statusText}`
          );
        }

        // Convert the response to a Blob
        const blob = await response.blob();

        // Extract the filename from the URL (last part after the last '/')
        const fileName = url.substring(url.lastIndexOf("/") + 1);

        // Create and return a File object with the Blob data
        return new File([blob], fileName, { type: blob.type });
      } catch (error) {
        console.error("Error fetching file from URL:", error);
        throw error;
      }
    };

    // Prepare the payload
    const payload = {
      subject: formData.subject,
      description: formData.description,
      attachments: [],
    };

    // Process attachments
    for (const attachment of formData.attachments) {
      if (attachment instanceof File) {
        payload.attachments.push(attachment);
      } else if (attachment.url) {
        const file = await fetchFileFromUrl(attachment.url);
        payload.attachments.push(file);
      }
    }

    console.log(payload, "Payload");

    try {
      if (id === "add") {
        await postCustomerTicket(payload);
      } else {
        await editCustomerTicket(payload, id);
      }

      // Reset form and previews after successful submission
      setFormData(initialData);
      setPreviews([]);
      goto("/client/raise-ticket");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="profile-section"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        className="bg-white bg-space-radius"
        style={{ width: "50%", margin: "0 auto", height: "auto" }}
      >
        <div className="">
          <h3 className="sub-title mb-4">
            {id === "add" ? "Add Ticket" : `Edit Ticket ${ticketId}`}
          </h3>
          <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Form.Group controlId="formSubject" className="mb-4">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                placeholder="Enter subject"
                value={formData.subject}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mb-4">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={4}
                placeholder="Enter description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-4">
              <Form.Label>Attachments</Form.Label>
              <Form.Control
                type="file"
                name="attachments"
                multiple // Allow multiple file selection
                accept="image/jpeg, image/png, image/jpg, application/pdf"
                onChange={handleFileChange}
              />
              <div className="text-muted">
                Drag files to attach, or{" "}
                <span className="text-primary">Browse</span>
              </div>
            </Form.Group>

            {/* Preview Section for Images and PDFs */}
            <div className="mt-3">
              {previews.map((preview, index) => (
                <div
                  key={index}
                  className="d-inline-block me-2 mb-2 position-relative"
                >
                  {preview.type === "image" ? (
                    <Image
                      src={preview.url}
                      thumbnail
                      alt="Selected Image"
                      style={{ width: "100px", height: "100px" }}
                    />
                  ) : (
                    <div className="d-inline-flex flex-column align-items-center">
                      <FaFilePdf size={50} color="#f00" />
                      <p className="file-name text-center mb-1">
                        {preview.name}
                      </p>
                    </div>
                  )}
                  <Button
                    buttonMode="button"
                    type="danger"
                    size="extra-small"
                    label="&times;"
                    className="position-absolute top-0 end-0"
                    style={{ transform: "translate(50%, -50%)" }}
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
            </div>

            <div
              className="d-flex justify-content-end mt-4"
              style={{ gap: "15px" }}
            >
              <Button
                onClick={() => {
                  goto(-1);
                }}
                label="Cancel"
                type="secondary"
                buttonMode="button"
              />

              <Button
                label={id === "add" ? "Create Ticket" : "Update Ticket"}
                type="primary"
                buttonMode="submit"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RaiseTicketForm;
