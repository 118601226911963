import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import { Button, Modal } from "react-bootstrap";
import { getIntervals, getTaskValues } from "../../../../Service";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import UptimeChart from "../../../../Components/UptimeChart";
import { formatDate } from "../../../../Utils";

const TaskSummaryPopup = (props) => {
  const [data, setData] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [chartValue, setChartValue] = useState();
  const [chartData, setChartData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchTaskValue = async () => {
      try {
        const data = await getTaskValues(props.id);
        setData(data.data);
        setTaskName(data.data[0].monitoring_task_name);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchIntervals = async () => {
      try {
        const data = await getIntervals(props.id);
        setChartValue(data);

        const formattedData = [];

        Object.keys(data).forEach((key) => {
          const keyData = data[key];
          keyData.forEach((item) => {
            formattedData.push({
              time: new Date(item.interval_start).getTime(), // Convert to timestamp for X-Axis
              uptime: parseFloat(item.uptime_percentage), // Y-Axis: Uptime percentage as a float
              label: key, // Include the key as a label for legend or grouping
            });
          });
        });

        setChartData(formattedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchIntervals();
    fetchTaskValue();
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "updated_at", // Keeps the original value for sorting
        accessorFn: (row) => new Date(row.updated_at), // Use Date object for sorting
        header: "Updated At",
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "node",
        header: "Node",
      },
      {
        accessorKey: "device_id",
        header: "Device ID",
      },
      {
        accessorKey: "task_value",
        header: "Status",
      },
      {
        accessorKey: "task_id",
        header: "Task ID",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: false,
    enableRowActions: false,
  });

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Task Summary of {taskName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex justify-content-end mb-3"
          style={{ gap: "10px" }}
        >
          <Button
            variant="primary"
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            {showTable ? "Graphical" : "Tabular"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setRefresh(!refresh);
            }}
          >
            Refresh
          </Button>
        </div>
        {showTable ? (
          <MaterialReactTable table={table} />
        ) : (
          <UptimeChart chartData={chartData} chartValue={chartValue} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TaskSummaryPopup;
