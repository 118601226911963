import React, { useEffect, useState } from "react";
import "./style.css";
import addIcon from "../../../Assets/Images/add-icon.svg";
import closeIcon from "../../../Assets/Images/close.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../../../Components/Button";
import { createServices, editServiceList } from "../../../Service";

const initialForm = {
  service_name: "",
  parameters: [],
  return_type: "String",
};

const ServicePopup = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newParameter, setNewParameter] = useState({
    name: "",
    type: "String",
    show: true,
  });
  const [disabled, setDisabled] = useState(false);
  const [otherInputType, setOtherInputType] = useState("");
  const [otherInputValue, setOtherInputValue] = useState("");

  useEffect(() => {
    if (props.mode === "edit") {
      const { customer_count, parameters, ...service } = props.serviceData;
      setForm({
        ...service,
        parameters: parameters.map((param) => ({
          ...param,
          type: param.type || "String",
        })),
      });
    }
  }, [props.serviceData, props.mode, props.show]);

  useEffect(() => {
    if (!props.show) {
      setNewParameter({
        name: "",
        type: "String",
        show: true,
      });
      setForm(initialForm);
    }
  }, [props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function onNewParameterChange(e) {
    const { name, value } = e.target;
    setNewParameter((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function addParameter() {
    const newParam =
      newParameter.type !== "Other"
        ? {
            ...newParameter,
            type: newParameter.type,
            show: newParameter.show,
            ...(props.mode === "edit" && { id: "" }),
          }
        : {
            ...newParameter,
            type: newParameter.type,
            custom_type: otherInputType,
            value: otherInputValue,
            show: false,
            ...(props.mode === "edit" && { id: "" }),
          };

    setForm((prev) => ({
      ...prev,
      parameters: [...prev.parameters, newParam],
    }));

    setNewParameter({
      name: "",
      type: "String",
      show: true,
    });
    setOtherInputType("");
  }

  function removeParameter(index) {
    setForm((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((_, i) => i !== index),
    }));
  }

  function toggleShow(index) {
    setForm((prev) => ({
      ...prev,
      parameters: prev.parameters.map((param, i) =>
        i === index ? { ...param, show: !param.show } : param
      ),
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (!disabled) {
      const formData = {
        ...form,
        parameters: form.parameters.map((param) => ({
          ...param,
          type: param.type,
        })),
      };

      setDisabled(true);
      try {
        if (props.mode === "add") {
          await createServices(formData);
        } else if (props.mode === "edit") {
          await editServiceList(formData);
        }
        props.added();
        props.onHide();
        setForm(initialForm);
      } catch (err) {
        console.log(err);
      } finally {
        setDisabled(false);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.mode === "add" ? "Add Task" : "Edit Task"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Task Name</Form.Label>
            <Form.Control
              type="text"
              value={form.service_name}
              name="service_name"
              onChange={onChange}
            />
          </Form.Group>

          {/* Parameter input */}
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <div className="w-100 me-4">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Input Parameter Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newParameter.name}
                  name="name"
                  onChange={onNewParameterChange}
                />
              </Form.Group>
            </div>
            <div className="w-100">
              <Form.Group className="mb-3">
                <Form.Label>Input Parameter Type</Form.Label>
                <Form.Select
                  value={newParameter.type}
                  name="type"
                  onChange={onNewParameterChange}
                >
                  <option value="String">String</option>
                  <option value="Integer">Integer</option>
                  <option value="Boolean">Boolean</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </div>
            {newParameter.type !== "Other" && (
              <Form.Group>
                <Form.Check
                  type="switch"
                  id="custom-switch-1"
                  checked={newParameter.show}
                  onChange={() =>
                    setNewParameter((prev) => ({ ...prev, show: !prev.show }))
                  }
                />
              </Form.Group>
            )}
            {newParameter.type !== "Other" && (
              <Button
                icon={addIcon}
                size="equal"
                onClick={addParameter}
                buttonMode="button"
              />
            )}
          </div>

          {newParameter.type === "Other" && (
            <div
              className="d-flex align-items-end mb-3 w-100"
              style={{ gap: "10px" }}
            >
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  value={otherInputType}
                  onChange={(e) => setOtherInputType(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  value={otherInputValue}
                  onChange={(e) => setOtherInputValue(e.target.value)}
                />
              </Form.Group>
              <Button
                icon={addIcon}
                size="equal"
                onClick={addParameter}
                buttonMode="button"
              />
            </div>
          )}

          {/* Existing parameters list */}
          <div className="services-admin-list-input">
            <div className="scroll-height">
              {form.parameters.map((param, index) => {
                const typeArray = ["String", "Integer", "Boolean"];
                const isOther = typeArray.includes(param.type);
                return (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between w-100 parameter-card"
                  >
                    <span style={{ display: "flex", gap: "5px" }}>
                      <span>
                        {param.name}/{param.type}
                        {`${
                          param.type === "Other"
                            ? ` - ${param.custom_type}(${param.value})`
                            : ""
                        }`}
                      </span>
                      {isOther && (
                        <span className="form-check form-switch">
                          <input
                            className="form-check-input toggle-switch toggle-edit-switch"
                            type="checkbox"
                            checked={param.show}
                            onChange={() => toggleShow(index)}
                          />
                        </span>
                      )}
                    </span>
                    <span>
                      <img
                        className="closeButton"
                        src={closeIcon}
                        alt="close"
                        width={20}
                        height={20}
                        onClick={() => removeParameter(index)}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Return type select */}
          <Form.Group className="mb-3">
            <Form.Label>Return Type</Form.Label>
            <Form.Select
              value={form.return_type}
              name="return_type"
              onChange={onChange}
            >
              <option value="String">String</option>
              <option value="Integer">Integer</option>
              <option value="Boolean">Boolean</option>
            </Form.Select>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
              disabled={disabled}
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ServicePopup;
