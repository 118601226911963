import React from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import SidemenuAdmin from "../Components/SidemenuAdmin";
import SideMenuCustomer from "../Components/SideMenuCustomer";

const MainLayout = () => {
  const user = localStorage.getItem("user");
  return (
    <div className="layout-wraper">
      {user === "Admin" ? <SidemenuAdmin /> : <SideMenuCustomer />}
      <div className="main-body">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
