import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../../../Components/Button";
import {
  createDeviceList,
  editDeviceList,
  getCustomerList,
} from "../../../Service";
import { InputGroup } from "react-bootstrap";

const initialForm = {
  device_id: "",
  device_alias: "",
  customer_email: "",
};

const validationSchema = Yup.object({
  device_id: Yup.string().required("Device Id is required"),
  device_alias: Yup.string().required("Device Alias is required"),
  customer_email: Yup.string(),
});
const DevicePopup = (props) => {
  const [customerEmail, setCustomerEmail] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Ensure customer_email is set to an empty string if it is null or undefined
      if (!disabled) {
        const sanitizedValues = {
          ...values,
          customer_email: values.customer_email || "", // Set to empty string if null/undefined
        };

        setDisabled(true);

        if (props.mode === "add") {
          try {
            await createDeviceList(sanitizedValues); // Pass sanitized values
            props.added();
            props.onHide();
            resetForm();
          } catch (err) {
            console.log(err);
          } finally {
            setDisabled(false);
          }
        } else if (props.mode === "edit") {
          try {
            await editDeviceList(sanitizedValues, sanitizedValues.device_id); // Pass sanitized values
            props.added();
            props.onHide();
            resetForm();
          } catch (err) {
            console.log(err);
          } finally {
            setDisabled(false);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (
      props.mode === "edit" &&
      props.tableData &&
      formik.values !== props.tableData
    ) {
      formik.setValues(props.tableData);
    }
  }, [props.mode, props.show, props.tableData]);

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerList();
        setCustomerEmail(data.customer_data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomerList();
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={!disabled ? formik.handleSubmit : () => {}}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Device ID</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.device_id}
                name="device_id"
                value={formik.values.device_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.device_id && formik.touched.device_id
                }
                disabled={props.mode === "edit"}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.device_id}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Alias</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.device_alias}
                name="device_alias"
                value={formik.values.device_alias}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.device_alias && formik.touched.device_alias
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.device_alias}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Registered Email</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                // value={form.customer_email}
                name="customer_email"
                value={formik.values.customer_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.customer_email &&
                  formik.touched.customer_email
                }
              >
                <option value="">Select</option>
                {customerEmail.map((data) => {
                  return <option value={data.email}>{data.email}</option>;
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.customer_email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
              disabled={disabled}
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DevicePopup;
