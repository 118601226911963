import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "../../Components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { registerCustomer } from "../../Service";

const initialForm = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phone: "",
  address_1: "",
  address_2: "",
  state: "",
  city: "",
  country: "",
  zipcode: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])/,
      "Password must contain at least one number, one special character, and one letter"
    ),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  address_1: Yup.string().required("Address 1 is required"),
  address_2: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  zipcode: Yup.string(),
});

const RegisterForm = ({ goto }) => {
  const [form, setForm] = useState(initialForm);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await registerCustomer(values);
        resetForm();
        goto();
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "https://countriesnow.space/api/v0.1/countries"
        );
        setCountry(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountry();

    return () => {
      setForm(initialForm);
    };
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(
          `https://countriesnow.space/api/v0.1/countries/states/q?country=${formik.values.country}`
        );
        setState(response.data.data.states);
      } catch (err) {
        console.log(err);
      }
    };
    if (formik.values.country !== "") fetchState();
  }, [formik.values.country]);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          `https://countriesnow.space/api/v0.1/countries/state/cities/q?country=${formik.values.country}&state=${formik.values.state}`
        );
        setCity(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (formik.values.state !== "") fetchCity();
  }, [formik.values.state]);

  return (
    <div className="h-100 d-flex align-iems-center flex-column justify-content-center register-page">
      <div className="inside-right h-auto">
        <div className="inside-right-box">
          <h2>Register</h2>

          <Form onSubmit={formik.handleSubmit}>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "0 4%",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="formFirstName"
                style={{ width: "48%" }}
              >
                <Form.Label>First Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.first_name && formik.touched.first_name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.first_name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formLastName"
                style={{ width: "48%" }}
              >
                <Form.Label>Last Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.last_name && formik.touched.last_name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.last_name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.phone && formik.touched.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAddress1">
              <Form.Label>Address 1</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="address_1"
                  value={formik.values.address_1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.errors.address_1 && formik.touched.address_1
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address_1}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAddress1">
              <Form.Label>Address 2</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="address_2"
                  value={formik.values.address_2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.errors.address_2 && formik.touched.address_2
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address_2}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {/* Country, State, City, Zip Code */}
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "0 4%",
              }}
            >
              <Form.Group className="mb-3" style={{ width: "48%" }}>
                <Form.Label>Country</Form.Label>
                <InputGroup hasValidation>
                  <Form.Select
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.country && formik.touched.country
                    }
                  >
                    <option value="">Select</option>
                    {country.map((data) => (
                      <option key={data.country} value={data.country}>
                        {data.country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.country}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" style={{ width: "48%" }}>
                <Form.Label>State</Form.Label>
                <Form.Select
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                >
                  <option value="">Select</option>
                  {state.map((data) => (
                    <option key={data.name} value={data.name}>
                      {data.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" style={{ width: "48%" }}>
                <Form.Label>City</Form.Label>
                <Form.Select
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                >
                  <option value="">Select</option>
                  {city.map((data) => (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formZipcode"
                style={{ width: "48%" }}
              >
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.password && formik.touched.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
              <Button type="primary" label={"Register"} buttonMode="submit" />
            </div>
          </Form>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>
          Have an account?{" "}
          <span
            onClick={() => {
              goto();
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              color: isHovered ? "#0047ab" : "#082064", // Change color on hover
              cursor: "pointer",
              textDecoration: isHovered ? "underline" : "none", // Optional: Add underline on hover
            }}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default RegisterForm;
