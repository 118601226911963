import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../../Redux/reducer";
import monitoringIcon from "../../../../Assets/Images/monitoring-icon.svg";
import descriptionIcon from "../../../../Assets/Images/cil_description.svg";
import nodesIcon from "../../../../Assets/Images/objects.svg";
import frequencyIcon from "../../../../Assets/Images/frequency.svg";
import deviceIcon from "../../../../Assets/Images/devices-monitoring.svg";
import notificationIcon from "../../../../Assets/Images/notification-blue.svg";
import ConfigureCard from "../../../../Components/ConfigureCard";
import { useParams } from "react-router-dom";
import {
  enableTask,
  getConfigureDeviceList,
  getConfigureNodes,
  getMonitoringAlert,
  getMonitorList,
  postDailyTask,
} from "../../../../Service";
import TaskDescriptionPopup from "./TaskDescriptionPopup";
import MonitoringTaskPopup from "./MonitoringTaskPopup";
import MonitoringFrequencyPopup from "./MonitoringFrequencypopup";
import NodesPopup from "./NodesPopup";
import DevicesPopup from "./DevicesPopup";
import { Form } from "react-bootstrap";
import NotificationPopup from "./NotificationPopup";
import TaskSummaryPopup from "./TaskSummaryPopup";

const MonitorConfigure = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fixedDivWidth, setFixedDivWidth] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [monitorData, setMonitorData] = useState({});
  const [monitorTaskCardData, setMonitorCardData] = useState();
  const [monitorDataChange, setMonitorDataChange] = useState(true);
  const [descriptionCard, setDescriptionCard] = useState();
  const [frequencyCard, setFrequencyCard] = useState();
  const [nodesData, setNodesData] = useState({});
  const [nodesDataChange, setNodesDataChange] = useState(true);
  const [nodesCard, setNodesCard] = useState();
  const [deviceData, setDeviceData] = useState();
  const [deviceDataChange, setDeviceDataChange] = useState(true);
  const [deviceCard, setDeviceCard] = useState();
  const [notificationData, setNotificationData] = useState();
  const [notificationDataChange, setNotificationDataChange] = useState(true);
  const [notificationCard, setNotificationCard] = useState();

  const [showMonitorTask, setShowMonitorTask] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showFrequency, setShowFrequency] = useState(false);
  const [showNodes, setShowNodes] = useState(false);
  const [showDevices, setShowDevices] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const [showBurgerButton, setShowburgerbutton] = useState(false);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await getMonitorList(id);
        setMonitorData(data.tasks[0]);
        setMonitorCardData([
          data.tasks[0].service_name,
          `Severity : ${data.tasks[0].severity}`,
          `Threshold : ${data.tasks[0].threshold.condition}${
            data.tasks[0].threshold.value !== null &&
            data.tasks[0].threshold.value !== ""
              ? `(${data.tasks[0].threshold.value})`
              : ""
          }`,
        ]);
        setDescriptionCard([data.tasks[0].task_details]);
        setFrequencyCard([`Interval : ${data.tasks[0].frequency}`]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchService();
  }, [toggle, monitorDataChange]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const data = await getConfigureNodes(id);
        setNodesData(data.objects);
        const objectNames = data.objects.map((item) => item.object_name);
        setNodesCard(objectNames);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNodes();
  }, [nodesDataChange]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const data = await getConfigureDeviceList(id);
        // console.log(data, "Service Data");

        setDeviceData(data.devices);
        setDeviceCard(data.devices);
        // const objectNames = data.map((item) => item.object_name);
        // setNodesCard(objectNames);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDevices();
  }, [deviceDataChange]);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const data = await getMonitoringAlert(id);
        setNotificationData(data.alerts);
        // console.log(data.alert, "Data");

        const objectNames = data.alerts.map((item) => {
          const formattedString =
            item.alert_type.charAt(0).toUpperCase() +
            item.alert_type.slice(1).toLowerCase();
          const groupName = String(item.group_name);
          return `${formattedString} - ${groupName}`;
        });
        setNotificationCard(objectNames);
        // setNodesCard(objectNames);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotification();
  }, [notificationDataChange]);

  // Get the width of the .main-body dynamically
  useEffect(() => {
    // Locate the main-body element from MainLayout using DOM traversal
    const mainBodyElement = document.querySelector(".main-body");
    if (mainBodyElement) {
      setFixedDivWidth(mainBodyElement.offsetWidth);
    }

    // Adjust the width on window resize
    const handleResize = () => {
      if (mainBodyElement) {
        setFixedDivWidth(mainBodyElement.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setActivePage("monitoring"));
  }, [dispatch]);
  return (
    <div>
      <div className="configure-header" style={{ width: fixedDivWidth }}>
        <div className="d-flex align-items-center w-100">
          <div className="custom-menubar-item d-flex justify-content-between w-100 px-4 align-items-center">
            <div className="profile-img-content flex-row align-items-center">
              <h4 className="main-title mb-0 me-2">
                Configure {monitorData.name}
              </h4>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex align-items-baseline me-4">
                <div
                  className="mb-0 me-3 status-text d-flex"
                  style={{ gap: "10px" }}
                >
                  <span>{monitorData.enable ? "Running" : "Stopped"} </span>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch-1"
                      checked={monitorData.enable}
                      label=""
                      className=""
                      onChange={async (e) => {
                        try {
                          await enableTask({
                            action: e.target.checked ? 1 : 0,
                            task_id: id,
                          });
                          setToggle(!toggle);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </Form>
                </div>
              </div>
              <div className="menu-container">
                <div
                  className="menu-icon"
                  onClick={() => {
                    setShowburgerbutton(!showBurgerButton);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <i class="fa-solid fa-bars"></i>
                  </span>

                  {showBurgerButton && (
                    <div class="menu-buttons" id="menuButtons">
                      <div
                        className="menu-button-items"
                        onClick={async () => {
                          const device_id = deviceData.map((data) => {
                            return data.device_id;
                          });

                          const nodes = nodesData.map((data) => {
                            return data.object_id;
                          });
                          try {
                            await postDailyTask({
                              tasks: {
                                device_id,
                                task_id: id,
                                monitoring_task_name: monitorData.service_name,
                                nodes,
                                status: "Pending",
                                interval_option: monitorData.frequency,
                              },
                            });
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        Save
                      </div>
                      <div className="menu-button-items">Refresh</div>
                      <div className="menu-button-items">Test</div>
                      <div
                        className="menu-button-items"
                        onClick={() => {
                          setShowTask(true);
                        }}
                      >
                        Task Summary
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="configure-body">
        <ConfigureCard
          cardTitle="Monitoring Task"
          icon={monitoringIcon}
          cardData={monitorTaskCardData}
          onClickEdit={() => {
            setShowMonitorTask(true);
          }}
        />

        <ConfigureCard
          cardTitle="Description"
          icon={descriptionIcon}
          cardData={descriptionCard}
          onClickEdit={() => {
            setShowDescription(true);
          }}
        />

        <ConfigureCard
          cardTitle="Nodes"
          icon={nodesIcon}
          cardData={nodesCard}
          onClickEdit={() => {
            setShowNodes(true);
          }}
        />

        <ConfigureCard
          cardTitle="Monitoring Frequency"
          icon={frequencyIcon}
          cardData={frequencyCard}
          onClickEdit={() => {
            setShowFrequency(true);
          }}
        />

        <ConfigureCard
          cardTitle="Devices"
          icon={deviceIcon}
          cardData={deviceCard}
          cardType="device"
          onClickEdit={() => {
            setShowDevices(true);
          }}
        />

        <ConfigureCard
          cardTitle="Notification"
          icon={notificationIcon}
          cardData={notificationCard}
          onClickEdit={() => {
            setShowNotification(true);
          }}
        />
      </div>
      <TaskDescriptionPopup
        show={showDescription}
        onHide={() => setShowDescription(false)}
        success={() => {
          setMonitorDataChange(!monitorDataChange);
        }}
        data={monitorData}
        id={id}
      />

      <MonitoringTaskPopup
        show={showMonitorTask}
        onHide={() => setShowMonitorTask(false)}
        success={() => {
          setMonitorDataChange(!monitorDataChange);
        }}
        data={monitorData}
        id={id}
      />

      <MonitoringFrequencyPopup
        show={showFrequency}
        onHide={() => setShowFrequency(false)}
        data={monitorData}
        success={() => {
          setMonitorDataChange(!monitorDataChange);
        }}
        id={id}
      />

      <DevicesPopup
        show={showDevices}
        onHide={() => setShowDevices(false)}
        success={() => {
          setDeviceDataChange(!deviceDataChange);
        }}
        data={deviceData}
        id={id}
      />

      <NodesPopup
        show={showNodes}
        onHide={() => setShowNodes(false)}
        success={() => {
          setNodesDataChange(!nodesDataChange);
        }}
        data={nodesData}
        id={id}
      />

      <NotificationPopup
        show={showNotification}
        onHide={() => setShowNotification(false)}
        success={() => {
          setNotificationDataChange(!notificationDataChange);
        }}
        data={notificationData}
        id={id}
      />

      <TaskSummaryPopup
        show={showTask}
        onHide={() => setShowTask(false)}
        id={id}
      />
    </div>
  );
};

export default MonitorConfigure;
