import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { updateMonitorTaskDescription } from "../../../../Service";

const initialForm = {
  task_details: "",
};
const TaskDescriptionPopup = (props) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    setForm({
      task_details: props.data ? props.data.task_details : "",
      task_id: props.id,
    });
  }, [props.data, props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const data = await updateMonitorTaskDescription(form);
      //   props.added();
      props.success();
      props.onHide();
      setForm(initialForm);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Description
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Task Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder=""
              value={form.task_details}
              name="task_details"
              onChange={onChange}
            />
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TaskDescriptionPopup;
