import React, { useEffect, useMemo, useState } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import {
  deleteCustomerNotification,
  getNotificationList,
} from "../../../Service";
import Button from "../../../Components/Button";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import AlertBox from "../../../Components/AlertBox";
import NotificationPopup from "./NotificationPopup";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";

const Notification = () => {
  const [activeTab, setActiveTab] = useState("Email");
  const [data, setData] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [tableData, setTableData] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const data = await getNotificationList(activeTab);
        setData(data.alerts);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("notification"));
    fetchNotification();
  }, [activeTab, , isAdded, dispatch]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "group_name",
        header: "Name",
        size: 80,
      },
      {
        accessorKey: "parameters",
        accessorFn: (row) => `${String(row.parameters)}`,
        header: `${activeTab === "Email" ? "Email Address" : "Phone Number"}`,
      },
    ],
    [activeTab]
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue.value); // Update the active tab value
    console.log(newValue, "newValue");
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteCustomerNotification({
                      id: [row.original.id],
                    });
                    setAlert(false);
                    added();
                  } catch (error) {
                    console.error(error);
                  }
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{gap: '15px'}}>
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.id;
              });
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteCustomerNotification({ id: newArray });
                    setAlert(false);
                    setRowSelection({});
                    added();
                  } catch (error) {
                    console.error(error);
                  }
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Notification</h4>
      </div>
      <div className="bg-white bg-space-radius">
        <TabList defaultSelectedValue={activeTab} onTabSelect={handleTabChange}>
          <Tab value="Email">Email</Tab>
          <Tab value="SMS">SMS</Tab>
          <Tab value="Beep" disabled>
            Beep
          </Tab>
        </TabList>
        <div className="mt-3">
          <MaterialReactTable table={table} />
        </div>
      </div>

      <NotificationPopup
        show={modalShow}
        type={activeTab}
        tableData={tableData}
        mode={mode}
        onHide={() => setModalShow(false)}
        added={() => setIsAdded(!isAdded)}
      />

      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </div>
  );
};

export default Notification;
