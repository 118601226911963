import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import Button from "../../../Components/Button";
import { deleteCustomerList, getCustomerList } from "../../../Service";
import CustomerPopup from "./CustomerPopup";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import AlertBox from "../../../Components/AlertBox";
import CustomerDevicesAddPopup from "./CustomerDeviceAddPopup";

const Customer = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deviceShow, setDeviceShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [customerId, setCustomerId] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [email, setEmail] = useState("");
  const [deviceData, setDeviceData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerList();
        setData(data.customer_data);
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(setActivePage("customer"));
    fetchCustomerList();
  }, [isAdded, dispatch]);

  function added() {
    setIsAdded(!isAdded);
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Customer",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        size: 80,
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone",
        header: "Phone",
      },
      {
        accessorKey: "address",
        header: "Address",
        accessorFn: (row) =>
          `${row.address_1} ${row.address_2} ${row.city}, ${row.state}, ${row.country}`,
      },
      {
        accessorKey: "devices",
        header: "Devices",
        Cell: ({ cell, row }) => (
          <div>
            <div>
              {String(
                cell.getValue().map((device) => {
                  return device.device_alias;
                })
              )}
            </div>
            <span
              className="add-devices"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDeviceShow(true);
                setEmail(row.original.email);
                setDeviceData(row.original.devices);
              }}
            >
              + Add Devices
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setCustomerId(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteCustomerList({ email: [row.original.email] });
                    setAlert(false);
                    added();
                  } catch {}
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
          icon={addIcon}
          label="Add"
          type="primary"
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.email;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerList({ email: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Customers</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>
      <CustomerPopup
        show={modalShow}
        mode={mode}
        customerId={customerId}
        added={added}
        onHide={() => setModalShow(false)}
      />
      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
      <CustomerDevicesAddPopup
        show={deviceShow}
        email={email}
        data={deviceData}
        added={added}
        onHide={() => setDeviceShow(false)}
      />
    </>
  );
};

export default Customer;
