import React from "react";
import "./style.css";

const Button = ({
  type,
  icon,
  label,
  onClick,
  buttonMode,
  size,
  className,
  disabled,
}) => {
  return (
    <>
      {icon ? (
        <button
          className={`button-common button-icon ${
            type === "primary"
              ? "primary"
              : type === "danger"
              ? "danger"
              : "secondary"
          } ${
            size === "equal"
              ? "equal-button"
              : size === "extra-small"
              ? "extra-small"
              : ""
          } ${className ? className : ""}`}
          onClick={!disabled ? onClick : () => {}}
          type={buttonMode}
          disabled={disabled}
        >
          {icon && <img src={icon} alt="add" height={12} width={12} />}
          {label && <div>{label}</div>}
        </button>
      ) : (
        <button
          className={`button-common ${
            type === "primary"
              ? "primary"
              : type === "danger"
              ? "danger"
              : "secondary"
          } ${
            size === "equal"
              ? "equal-button"
              : size === "extra-small"
              ? "extra-small"
              : size === "default"
              ? "default"
              : "large-button"
          } ${className ? className : ""} large-button`}
          onClick={!disabled ? onClick : () => {}}
          type={buttonMode}
        >
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
