import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.css";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../Components/Button";
import {
  createCustomerNotification,
  updateCustomerNotification,
} from "../../../Service";
import close from "../../../Assets/Images/close-parameter-com.svg";
import addIcon from "../../../Assets/Images/add-icon.svg";

const initialForm = {
  name: "",
  parameter: [],
};

// Define Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  parameter: Yup.array().min(1, "At least one parameter is required"),
});

const NotificationPopup = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(""); // State for error messages
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!disabled) {
        const request_body = {
          alert_type: props.type,
          group_name: values.name,
          parameters: values.parameter,
        };

        setDisabled(true);

        try {
          if (props.mode === "add") {
            await createCustomerNotification(request_body);
          } else if (props.mode === "edit") {
            request_body.id = props.tableData.id;
            await updateCustomerNotification(request_body);
          }
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        } finally {
          setDisabled(false);
        }
      }
    },
  });

  useEffect(() => {
    if (
      props.mode === "edit" &&
      props.tableData &&
      formik.values.name !== props.tableData.group_name
    ) {
      formik.setValues({
        name: props.tableData.group_name,
        parameter: props.tableData.parameters,
      });
    } else {
      formik.resetForm();
    }
  }, [props.mode, props.show, props.tableData]);

  // Handle input change for parameter field
  function onInputChange(e) {
    setInputValue(e.target.value);
    setInputError(""); // Clear the error when typing
  }

  // Add a parameter to the array after validating based on type
  function addParameter() {
    const isEmail = props.type === "Email";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]+$/;

    if (isEmail) {
      if (emailRegex.test(inputValue.trim())) {
        formik.setFieldValue("parameter", [
          ...formik.values.parameter,
          inputValue.trim(),
        ]);
        setInputValue(""); // Clear input field
      } else {
        setInputError("Please enter a valid email address.");
      }
    } else if (props.type === "SMS") {
      if (phoneRegex.test(inputValue.trim())) {
        formik.setFieldValue("parameter", [
          ...formik.values.parameter,
          inputValue.trim(),
        ]);
        setInputValue(""); // Clear input field
      } else {
        setInputError("Please enter a valid phone number.");
      }
    }
  }

  // Remove a parameter from the array
  function removeParameter(index) {
    formik.setFieldValue(
      "parameter",
      formik.values.parameter.filter((_, i) => i !== index)
    );
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add {props.type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex align-items-end" style={{ gap: "10px" }}>
            <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
              <Form.Label>
                {props.type === "Email" ? "Email" : "Phone"}
              </Form.Label>
              <Form.Control
                type={props.type === "Email" ? "email" : "number"}
                placeholder=""
                value={inputValue}
                onChange={onInputChange}
                isInvalid={!!inputError} // Show validation error for input
              />
              <Form.Control.Feedback type="invalid">
                {inputError}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mb-3">
              <Button
                icon={addIcon}
                size="equal"
                onClick={addParameter}
                buttonMode="button"
              />
            </div>
          </div>

          {formik.errors.parameter && formik.touched.parameter && (
            <div className="text-danger">{formik.errors.parameter}</div>
          )}

          <div className="services-admin-list-input">
            <div className="scroll-height">
              <div className="parameter-wrapper-notification">
                {formik.values.parameter.map((param, index) => (
                  <span key={index} className="email-content-wrapper">
                    {param}
                    <img
                      src={close}
                      alt="close"
                      height={20}
                      width={20}
                      className="close-icon-parameter"
                      onClick={() => removeParameter(index)}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label="Save"
              buttonMode="submit"
              disabled={disabled}
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationPopup;
