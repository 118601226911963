import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Button from "../../../Components/Button";
import { getDeviceList, linkDeviceToCustomer } from "../../../Service";

const CustomerDevicesAddPopup = (props) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const data = await getDeviceList();
        setData(data.device_data);
        const newOptions = data.device_data.map((data) => {
          return { value: data.device_id, label: data.device_alias };
        });
        setOptions(newOptions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeviceList();
  }, []);

  useEffect(() => {
    if (props.data && data.length > 0) {
      const newOptions = props.data.map((data) => {
        return { value: data.device_id, label: data.device_alias };
      });
      setSelectedOptions(newOptions);
    }
  }, [props.data, props.show, data.length]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  async function onSubmit(e) {
    e.preventDefault();
    if (!disabled) {
      const devices = selectedOptions.map((data) => {
        return { device_id: data.value, device_alias: data.label };
      });
      setDisabled(true);
      try {
        await linkDeviceToCustomer({
          email: props.email,
          devices,
        });
        props.added();
        props.onHide();
      } catch (err) {
        console.log(err);
      } finally {
        setDisabled(false);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Devices</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            {/* <Form.Label>Monitoring Frequency</Form.Label> */}
            <Select
              isMulti
              placeholder="Please Select"
              value={selectedOptions}
              onChange={handleChange}
              options={options}
              isSearchable
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
              disabled={disabled}
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerDevicesAddPopup;
