import { Toaster } from "react-hot-toast";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import AdminRoutes from "./Routes/AdminRoutes";
import ClientRoutes from "./Routes/ClientRoutes";
import MainLayout from "./Layout/MainLayout";
import Customer from "./Pages/Admin/Customer";
import Dashboard from "./Pages/User/Dashboard";
import Devices from "./Pages/Admin/Devices";
import Services from "./Pages/Admin/Services";
import Reports from "./Pages/Admin/Reports";
import Tickets from "./Pages/Admin/Tickets";
import Notifications from "./Pages/Admin/Notification";
import Profile from "./Pages/User/Profile";
import MyDevice from "./Pages/User/MyDevice";
import Monitoring from "./Pages/User/Monitoring";
import Report from "./Pages/User/Reports";
import UserNodes from "./Pages/User/Nodes";
import RaiseTicket from "./Pages/User/RaiseTicket";
import UserNotifications from "./Pages/User/Notification";
import MonitorAdd from "./Pages/User/Monitoring/MonitorAdd";
import MonitorConfigure from "./Pages/User/Monitoring/MonitorConfigure";
import EditProfileForm from "./Pages/User/Profile/EditProfileForm";
import HandleAuthError from "./Utils/HandleAuthError";
import RaiseTicketForm from "./Pages/User/RaiseTicket/RaiseTicketForm";

function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <HandleAuthError />
        <Routes>
          <Route path="/" element={<Login />} />

          {/* Admin routes  */}
          <Route path="/admin" element={<AdminRoutes />}>
            <Route path="" element={<MainLayout />}>
              <Route index element={<Customer />} />
              <Route path="devices" element={<Devices />} />
              <Route path="services" element={<Services />} />
              <Route path="reports" element={<Reports />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="notification" element={<Notifications />} />
            </Route>
          </Route>

          {/* User routes */}
          <Route path="/client" element={<ClientRoutes />}>
            <Route path="" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="edit-profile" element={<EditProfileForm />} />
              <Route path="my-device" element={<MyDevice />} />
              <Route path="monitoring" element={<Monitoring />} />
              <Route path="monitoring-add" element={<MonitorAdd />} />
              <Route
                path="monitoring-configure/:id"
                element={<MonitorConfigure />}
              />
              <Route path="report" element={<Report />} />
              <Route path="nodes" element={<UserNodes />} />
              <Route path="raise-ticket" element={<RaiseTicket />} />
              <Route path="submit-ticket/:id" element={<RaiseTicketForm />} />
              <Route path="notification" element={<UserNotifications />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
