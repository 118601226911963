import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../../Redux/reducer";
import { Form } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { createMonitor, getServiceList } from "../../../../Service";
import { useNavigate } from "react-router-dom";

const initialForm = {
  name: "",
  service_name: "",
  parameters: {},
  threshold_condition: "",
  frequency: "",
  severity: "",
  task_details: "",
  threshold_value: "",
};
const MonitorAdd = () => {
  const [form, setForm] = useState(initialForm);
  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const goto = useNavigate();

  useEffect(() => {
    dispatch(setActivePage("monitoring"));
  }, [dispatch]);

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        const data = await getServiceList();
        setData(data.services);
      } catch (error) {
        console.log(error);
      }
    };
    fetchServiceList();
  }, []);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  // Handle service selection change
  function handleServiceChange(e) {
    const selectedService = data.find(
      (service) => service.service_name === e.target.value
    );
    setSelectedService(selectedService); // Set the selected service
    setForm((prev) => ({
      ...prev,
      service_name: selectedService?.service_name || "",
      parameters: {}, // Reset parameters
      threshold_condition:
        selectedService.return_type === "Boolean"
          ? "True"
          : selectedService.return_type === "Integer"
          ? "Greater Than"
          : selectedService.return_type === "String"
          ? "Equal To"
          : "", // Reset threshold condition
    }));
  }

  // Handle parameter input change
  function handleParameterChange(id, value) {
    setForm((prev) => ({
      ...prev,
      parameters: {
        ...prev.parameters,
        [id]: value, // Map each parameter ID to its value
      },
    }));
  }

  // Handle return_type select value change
  function handleReturnTypeChange(e) {
    setForm((prev) => ({
      ...prev,
      threshold_condition: e.target.value, // Set the threshold condition
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();

    if (!disabled) {
      // Prepare parameters for the request body
      const parametersArray = Object.keys(form.parameters).map((key) => ({
        id: key, // The parameter ID
        value: form.parameters[key], // The parameter value
      }));

      // Construct the request body
      const requestBody = {
        name: form.name,
        service_name: form.service_name,
        severity: form.severity,
        frequency: form.frequency,
        task_details: form.task_details,
        status: "Pending", // Assuming the status is always Pending for now
        threshold: {
          threshold_condition: form.threshold_condition,
          threshold_value:
            selectedService.return_type !== "Boolean"
              ? form.threshold_value
              : "",
        },
        parameters: parametersArray, // Array of parameter objects
      };
      setDisabled(true);
      try {
        const data = await createMonitor(requestBody);
        goto(`/client/monitoring-configure/${data.data.id}`);
        //   props.added();
        //   props.onHide();
        setForm(initialForm);
      } catch (err) {
        console.log(err);
      } finally {
        setDisabled(false);
      }
    }
  }

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Add New Monitoring Task</h4>
        </div>
        <div className="bg-white bg-space-radius">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={form.name}
                    name="name"
                    onChange={onChange}
                  />
                </Form.Group>

                {/* Select to choose which service to monitor */}
                <Form.Group className="mb-3">
                  <Form.Label>What do you like to monitor</Form.Label>
                  <Form.Select
                    value={form.service_name}
                    name="service_name"
                    onChange={handleServiceChange}
                  >
                    <option value="">Select</option>
                    {data.map((service) => (
                      <option
                        key={service.service_id}
                        value={service.service_name}
                      >
                        {service.service_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {/* Dynamically render inputs based on parameters */}
                {selectedService?.parameters.map((param) => (
                  <Form.Group key={param.id} className="mb-3">
                    <Form.Label>{param.name}</Form.Label>
                    <Form.Control
                      type={`${
                        param.name === "Password" ? "password" : "text"
                      }`}
                      placeholder={`Enter ${param.name}`}
                      value={form.parameters[param.id] || ""}
                      onChange={(e) =>
                        handleParameterChange(param.id, e.target.value)
                      }
                    />
                  </Form.Group>
                ))}

                <div className="grid-1fr">
                  <Form.Group className="mb-3">
                    <Form.Label>Monitoring Frequency</Form.Label>
                    <Form.Select
                      value={form.frequency}
                      name="frequency"
                      onChange={onChange}
                    >
                      <option value="">Select an option</option>
                      <option value="5 Minutes">5 Minutes</option>
                      <option value="10 Minutes">10 Minutes</option>
                      <option value="15 Minutes">15 Minutes</option>
                      <option value="30 Minutes">30 Minutes</option>
                      <option value="Hourly">Hourly</option>
                      <option value="Daily">Daily</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Severity Levels</Form.Label>
                    <Form.Select
                      value={form.severity}
                      name="severity"
                      onChange={onChange}
                    >
                      <option value="">Select an option</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </Form.Select>
                  </Form.Group>
                </div>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Task Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder=""
                    value={form.task_details}
                    name="task_details"
                    onChange={onChange}
                  />
                </Form.Group>

                {/* Render select box based on return_type */}
                {selectedService && (
                  <div className="grid-1fr">
                    <Form.Group className="mb-3">
                      <Form.Label>Threshold Condition</Form.Label>
                      {selectedService.return_type === "Boolean" ? (
                        <Form.Select
                          value={form.threshold_condition}
                          placeholder="Select Return Type"
                          onChange={handleReturnTypeChange}
                        >
                          <option value="True">True</option>
                          <option value="False">False</option>
                        </Form.Select>
                      ) : selectedService.return_type === "String" ? (
                        <Form.Select
                          value={form.threshold_condition}
                          placeholder="Select Return Type"
                          onChange={handleReturnTypeChange}
                        >
                          <option value="Equal To">Equal To</option>
                          <option value="Contains">Contains</option>
                          <option value="Does not Contain">
                            Does not Contain
                          </option>
                        </Form.Select>
                      ) : selectedService.return_type === "Integer" ? (
                        <Form.Select
                          value={form.threshold_condition}
                          placeholder="Select Return Type"
                          onChange={handleReturnTypeChange}
                        >
                          <option value="Greater Than">Greater Than</option>
                          <option value="Less Than">Less Than</option>
                          <option value="Equal To">Equal To</option>
                          <option value="Between">Between</option>
                        </Form.Select>
                      ) : null}
                    </Form.Group>

                    {selectedService.return_type !== "Boolean" && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Value</Form.Label>
                        <Form.Control
                          type={`${
                            selectedService.return_type === "Integer"
                              ? "number"
                              : "text"
                          }`}
                          placeholder=""
                          value={form.threshold_value}
                          name="threshold_value"
                          onChange={onChange}
                        />
                      </Form.Group>
                    )}
                  </div>
                )}

                <div
                  className="d-flex justify-content-end"
                  style={{ gap: "15px" }}
                >
                  <Button
                    type="primary"
                    label="Submit"
                    buttonMode="submit"
                    disabled={disabled}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonitorAdd;
