import React from "react";
import Button from "../Button";
import editIcon from "../../Assets/Images/edit-gray.svg";

const ConfigureCard = ({
  icon,
  cardTitle,
  cardData,
  cardType,
  onClickEdit,
}) => {
  return (
    <div className="bg-white bg-space-radius h-100">
      <div style={{ float: "right" }}>
        <Button
          icon={editIcon}
          type="secondary"
          onClick={() => {
            onClickEdit && onClickEdit();
          }}
        />
      </div>
      <div className="w-100 text-center mt-4">
        <div className="monitorig-icon-style">
          <img src={icon} alt="icon" />
        </div>
        <h5 className="mt-3">{cardTitle}</h5>
      </div>
      <ul className="ul-monitoring scroll-height mt-4">
        {cardType === "device" ? (
          cardData === null || cardData === undefined ? (
            <li>Loading</li>
          ) : cardData.length === 0 ? (
            <li>No Data</li>
          ) : (
            cardData.map((data) => {
              return (
                <li className="d-flex align-items-center justify-content-between">
                  <span>{data.device_alias}</span>
                  <span
                    className={`${
                      data.device_status === "Online" ? "online" : "offline"
                    }`}
                  >
                    {data.device_status}
                  </span>
                </li>
              );
            })
          )
        ) : cardData === null || cardData === undefined ? (
          <li>Loading</li>
        ) : cardData.length === 0 ? (
          <li>No Data</li>
        ) : (
          cardData.map((data) => {
            return <li>{data}</li>;
          })
        )}
      </ul>
    </div>
  );
};

export default ConfigureCard;
