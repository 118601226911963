import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthError: false, // State to handle 401/403 errors
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.isAuthError = action.payload; // Set the auth error state
    },
  },
});

export const { setAuthError } = authSlice.actions;

export default authSlice.reducer;
