import axios from "axios";
import toast from "react-hot-toast";
import { store } from "../Redux/store";
import { setAuthError } from "../Redux/authSlice";

const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // const data = response?.data;
    // console.log(response, "Status Code");
    // toast.success("Success 1");
    // if (response.status === 200) {
    //   toast.success("Data Fetched Successfully");
    // }
    if (response.status === 201) {
      toast.success(response.data?.message, {
        position: "top-right",
      });
    }
    if (response.status === 204) {
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    }
    return response; // Return response if successful
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // If the response status is 401, call the logout function
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Unauthorized, Please Login Again",
        {
          position: "top-right",
        }
      );

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      store.dispatch(setAuthError(true));
      // window.location.href = "/";
    } else if (error.response && error.response.status === 400) {
      toast(
        error.response.data.message
          ? error.response.data.message
          : "Unauthorized, Please Login Again",
        {
          icon: "⚠️",
          style: {
            border: "1px solid #FFA500",
            padding: "16px",
            // color: "#FFA500",
          },
          position: "top-right",
        }
      );
    } else {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Internal Server Error",
        {
          position: "top-right",
        }
      );
    }

    return Promise.reject(error); // Reject the promise with the error
  }
);

export default api;
