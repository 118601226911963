import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const NetworkSpeed = ({ chartData }) => {
  if (!chartData || Object.keys(chartData).length === 0) {
    return <div>No data available for the chart.</div>;
  }

  // Transform data to have a consistent structure across nodes for the chart
  const transformedData = [];

  Object.keys(chartData).forEach((node) => {
    chartData[node].forEach((entry) => {
      const existingEntry = transformedData.find(
        (dataPoint) => dataPoint.interval_start === entry.interval_start
      );

      if (existingEntry) {
        existingEntry[node] = parseFloat(entry.avg_speed);
      } else {
        transformedData.push({
          interval_start: entry.interval_start,
          [node]: parseFloat(entry.avg_speed),
        });
      }
    });
  });

  // Array of colors to be assigned to each line
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#387908",
    "#ff0000",
    "#0033cc",
    "#9933ff",
    "#cc0099",
    "#009966",
    "#ffcc00",
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={transformedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="interval_start"
            tickFormatter={(timeStr) =>
              new Date(timeStr).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }
            label={{ value: "Time", position: "bottom" }}
          />
          <YAxis
            label={{
              value: "Average Speed",
              angle: -90,
              position: "insideLeft",
            }}
            domain={["auto", "auto"]}
          />
          <Tooltip
            labelFormatter={(time) =>
              `Time: ${new Date(time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`
            }
          />
          <Legend verticalAlign="top" height={36} />
          {Object.keys(chartData).map((node, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={node}
              stroke={colors[index % colors.length]} // Cycle through colors
              strokeWidth={3}
              activeDot={{ r: 8 }}
              name={node}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NetworkSpeed;
