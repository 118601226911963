import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthError } from "../Redux/authSlice";

const HandleAuthError = () => {
  const navigate = useNavigate(); // Now useNavigate will work correctly inside BrowserRouter
  const dispatch = useDispatch();
  const isAuthError = useSelector((state) => state.auth.isAuthError); // Select the auth error state from Redux

  useEffect(() => {
    if (isAuthError) {
      navigate("/"); // Redirect to login page
      dispatch(setAuthError(false)); // Reset the auth error state after redirect
    }
  }, [isAuthError, navigate, dispatch]);

  return null; // This component doesn't need to render anything
};

export default HandleAuthError;
