import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { postAFrequency } from "../../../../Service";

const initialForm = {
  option: "",
};
const MonitoringFrequencyPopup = (props) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    setForm({
      option: props.data?.frequency,
      task_id: props.id,
    });
  }, [props.data, props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const data = await postAFrequency(form);
      //   props.added();
      props.onHide();
      props.success();
      setForm(initialForm);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Monitoring Frequency
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Monitoring Frequency</Form.Label>
            <Form.Select value={form.option} name="option" onChange={onChange}>
              <option value="">Select an option</option>
              <option value="5 Minutes">5 Minutes</option>
              <option value="10 Minutes">10 Minutes</option>
              <option value="15 Minutes">15 Minutes</option>
              <option value="30 Minutes">30 Minutes</option>
              <option value="Hourly">Hourly</option>
              <option value="Daily">Daily</option>
            </Form.Select>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MonitoringFrequencyPopup;
