import React from "react";
import "./style.css";

const ProgressBar = ({
  successPercentage,
  failurePercentage,
  successCount,
  failureCount,
  totalCount,
}) => {
  const pendingPercentage = 100 - (successPercentage + failurePercentage);
  const pendingCount = totalCount - (successCount + failureCount);

  const tooltipText = `Success: ${successCount}, Failed: ${failureCount}, Pending: ${pendingCount}`;

  return (
    <div className="progress-bar-container" title={tooltipText}>
      <div
        className="progress-section green"
        style={{ width: `${successPercentage}%` }}
      ></div>
      <div
        className="progress-section red"
        style={{ width: `${failurePercentage}%` }}
      ></div>
      <div
        className="progress-section white"
        style={{ width: `${pendingPercentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
