import React, { useEffect, useState } from "react";
import { getCustomerDetails } from "../../../Service";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import "./style.css";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();
  const goto = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoaded(false);
      try {
        const data = await getCustomerDetails();
        setData(data.customer_data);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("profile"));

    fetchProfileData();
  }, [dispatch]);

  useEffect(() => {
    console.log(data, "customer Data");
  }, [data]);
  return (
    <div className="bg-white bg-space-radius">
      {isLoaded && (
        <>
          <div className="profile-section mt-4">
            <div className="profile-space">
              <div className="profile-heading">{`${data.first_name} ${data.last_name}`}</div>
              <div className="profile-detail-wraper">
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Email</div>
                  <div>:</div>
                  <div className="profile-value">{data.email}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Mobile</div>
                  <div>:</div>
                  <div className="profile-value">{data.phone}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">City</div>
                  <div>:</div>
                  <div className="profile-value">{data.city}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Country</div>
                  <div>:</div>
                  <div className="profile-value">{data.country}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-alignment">
            <Button
              label="Edit Profile"
              type="primary"
              onClick={() => {
                goto("/client/edit-profile");
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
