import React, { useMemo } from "react";
import { MRT_Table, useMaterialReactTable } from "material-react-table";

const TopTenChart = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "rank",
        header: "#",
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "value",
        header: "Alert Sent",
        size: 50,
      },
    ],
    []
  );

  // const dummyData = [
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  // ]

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableTableHead: true, // Keeps table headers
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        "&:nth-of-type(odd)": {
          backgroundColor: "rgba(0, 0, 0, 0.04) !important",
        },
      },
    },
    muiTableProps: {
      sx: {
        border: "0px solid rgba(81, 81, 81, .5)",
        maxWidth: "100%", // Reduce table width
        fontSize: "0.85rem", // Smaller font size
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        // fontStyle: "italic",
        fontWeight: "600",
        padding: "4px 8px", // Smaller padding
        // background: '#000',
        color: "#5578ba",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        padding: "4px 8px", // Smaller padding
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr": {
          height: "32px", // Reduce row height
        },
      },
    },
  });

  return (
    <div style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}>
      <MRT_Table table={table} />
    </div>
  );
};

export default TopTenChart;
