import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import "./style.css";

const ReadMore = ({ text, heading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClipped, setIsClipped] = useState(false);
  const textRef = useRef(null);

  // Function to open the popup
  const openPopup = () => {
    setIsOpen(true);
  };

  // Function to close the popup
  const handleClose = () => {
    setIsOpen(false);
  };

  // Check if the text is overflowing (clipped)
  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsClipped(element.scrollHeight > element.clientHeight);
    }
  }, [text]);

  return (
    <div>
      {/* Clipped Text */}
      <p
        ref={textRef}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </p>

      {/* "Read More" Button (Shown only if text is clipped) */}
      {isClipped && (
        <button onClick={openPopup} className="read-more">
          Read More
        </button>
      )}

      {/* Popup with Full Text */}
      {isOpen && (
        <Modal
          show={isOpen}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
          style={{ zIndex: 1500 }}
        >
          <Modal.Header closeButton>{heading}</Modal.Header>
          <Modal.Body>
            <p>{text}</p>
            <div className="d-flex justify-content-end">
              <Button onClick={handleClose} label="Close" buttonMode="button" />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ReadMore;
