import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./reducer";
import authReducer from "./authSlice";

export const store = configureStore({
  reducer: {
    dmon: Reducer,
    auth: authReducer,
  },
});
