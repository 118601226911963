import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonCustom from "../Button";
import Button from "react-bootstrap/Button";

const AlertBox = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure want to delete?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="py-4">

        The selected rows will be deleted
        </p>
      <div className="d-flex align-items-center justify-content-end w-100">
      <ButtonCustom onClick={props.onHide} label="Close" />
        <Button
          variant="danger"
          onClick={() => {
            props.deleteData.onClick();
          }}
          className="large-button ms-3"
        >
          Delete
        </Button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
       
      </Modal.Footer> */}
    </Modal>
  );
};

export default AlertBox;
