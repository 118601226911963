import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import filterIcon from "../../../Assets/Images/file.svg";
import { getAdminTickets } from "../../../Service";
import { formatDate } from "../../../Utils";

const Tickets = () => {
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_by",
        header: "Requested By",
        accessorFn: (row) => `${row.created_by.email}`,
      },
      {
        accessorKey: "ticket_id",
        header: "Ticket Id",
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell, row }) => {
          return (
            <>
              <div>{row.original.subject}</div>
              <div>{row.original.description}</div>
            </>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "created_at",
        header: "Created Date",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const data = await getAdminTickets();
        setData(data.tickets);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("ticket"));
    fetchTickets();
  }, [dispatch]);

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: false,
    positionActionsColumn: "last",
    // renderRowActions: ({ row, table }) => (
    //   <Box sx={{ display: "flex", gap: "1rem" }}>
    //     <Tooltip title="Edit">
    //       <IconButton
    //         onClick={() => {
    //           setMode("edit");
    //           setServiceData(row.original);
    //           setModalShow(true);
    //         }}
    //       >
    //         <img src={editIcon} alt="edit" />
    //       </IconButton>
    //     </Tooltip>
    //     <Tooltip title="Delete">
    //       <IconButton
    //         onClick={() => {
    //           setAlert(true);
    //           setDeleteData({
    //             onClick: async () => {
    //               try {
    //                 await deleteServiceList({
    //                   service_ids: [row.original.service_id],
    //                 });
    //                 setAlert(false);
    //                 added();
    //               } catch {}
    //             },
    //           });
    //         }}
    //       >
    //         <img src={deleteIcon} alt="delete" />
    //       </IconButton>
    //     </Tooltip>
    //   </Box>
    // ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          icon={filterIcon}
          label="Filter"
          type="primary"
          onClick={() => {
            // setMode("add");
            // setModalShow(true);
          }}
        />
      </div>
    ),
  });

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Tickets</h4>
      </div>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default Tickets;
