import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const UptimeChart = ({ chartData, chartValue }) => {
  if (!chartData || chartData.length === 0) {
    return <div>No data available for the chart.</div>;
  }

  const startOfDay = new Date().setHours(0, 0, 0, 0); // 12:00 AM
  const endOfDay = new Date().setHours(23, 59, 59, 999); // 11:59 PM

  // Array of colors for the lines
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#387908",
    "#ff0000",
    "#0033cc",
    "#9933ff",
    "#cc0099",
    "#009966",
    "#ffcc00",
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            type="number"
            domain={[startOfDay, endOfDay]}
            tickFormatter={(timeStr) =>
              new Date(timeStr).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }
            scale="time"
            label={{ value: "Hour", position: "bottom" }}
          />
          <YAxis
            label={{
              value: "Uptime Percentage",
              angle: -90,
              position: "insideLeft",
            }}
            domain={[0, 100]} // Y-axis domain from 0 to 100%
          />
          <Tooltip
            labelFormatter={(time) =>
              `Time: ${new Date(time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`
            }
          />
          <Legend verticalAlign="top" height={36} />
          {chartValue &&
            Object.keys(chartValue).map((key, index) => (
              <Line
                key={index}
                type="monotone"
                data={chartData.filter((item) => item.label === key)}
                stroke={colors[index % colors.length]} // Cycle through colors
                strokeWidth={3}
                activeDot={{ r: 8 }}
                name={key} // Label for legend
                dataKey="uptime"
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UptimeChart;
