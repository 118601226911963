import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const ThirtyDayGraph = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available for the chart.</div>;
  }

  // Parse the date to a Date object for each data point
  const chartData = data.map((item) => ({
    ...item,
    date: new Date(item.date).getTime(), // Convert date string to timestamp for XAxis
  }));

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={(dateStr) =>
              new Date(dateStr).toLocaleDateString([], {
                month: "short",
                day: "numeric",
              })
            }
            scale="time"
            label={{ value: "Date", position: "bottom" }}
          />
          <YAxis
            label={{
              value: "Percentage",
              angle: -90,
              position: "insideLeft",
            }}
            domain={[0, 100]} // Y-axis domain from 0 to 100%
          />
          <Tooltip
            labelFormatter={(date) =>
              `Date: ${new Date(date).toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}`
            }
          />
          {/* <Legend verticalAlign="top" height={36} /> */}
          <Line
            type="monotone"
            dataKey="percentage"
            stroke="#8884d8"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            name="" // Name in the legend
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ThirtyDayGraph;
